<template>
    <div id="process" style="width: 100%; height: 25vh; "></div>
</template>

<script>

export default {
    data() {
        return {
        };
    },
    created() {
    },
    mounted() {
        this.drawChart();
    },
    methods: {
        drawChart() {
            var myChart = this.$echarts.init(document.getElementById("process"))
            // 指定图表的配置项和数据
            var option = {
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        data: [820, 932, 901, 934, 1290, 1330, 1320],
                        type: 'line',
                        smooth: true
                    }
                ],
                grid: {
                    y: '10%',
                    y2: '10%',
                },
            };
            // 使用刚指定的配置项和数据显示图表。
            option && myChart.setOption(option);
        },
    },
};
</script>